<template>
    <div class="page-main">
        <a-form :form="searchForm"
            layout="inline">
            <a-form-item label="关键字">
                <a-input v-model="searchForm.word"
                        placeholder="关键字" />
            </a-form-item>
            <a-form-item label="状态" >
                <a-select v-model="searchForm.enable"
                            style="width: 120px">
                    <a-select-option value="false">禁用</a-select-option>
                    <a-select-option value="true">启用</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item >
            <a-button type="primary"
                        icon="search"
                        @click="handleSearch">
                搜索
            </a-button>
            </a-form-item>
            <a-form-item >
            <a-button 
                        icon="reload"
                        @click="resetSearchForm">
                重置
            </a-button>
            </a-form-item>
        </a-form>
        <a-card :bordered='false'>
            <a-row style="text-align:right;margin-bottom:20px;">
                <a-button type="primary" icon="plus" @click="handleAddAutomatic">添加</a-button>
            </a-row>
            <a-table 
                :loading="loading"
                :columns="automaticColumns" 
                :data-source="automaticData"
                :row-key="record => record.id"
                :pagination="false"
            >
                <span slot="status" slot-scope="text">
                    <a-tag
                        :color="text===true?'green' : 'red'"
                    >
                        {{text===true?'启用' : '禁用'}}
                    </a-tag>
                </span>
                <div slot="time" slot-scope="text">
                    {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                </div>
                <template slot="action" slot-scope="text,record">
                    <div class="operation-wrapper">
                        <!-- <a-button type="link" @click="showDetail(record)">查看</a-button> -->
                        <a-button type="link" @click="updateAutomatic(record)">编辑</a-button>
                        <!-- <a-button type="link" style="color:red">删除</a-button> -->
                    </div>  
                </template>
            </a-table>
        </a-card>
        <a-modal v-model="isShowAdd" title="编辑自动回复" @ok="handleConfirm">
            <h4 style="color:#FECD50;text-align:center;">建议您多个关键词之间用空格隔开，方便后期使用</h4>
            <a-form :form="addForm">
                <a-form-item 
                    label="关键词"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }">
                    <a-input v-model="addForm.word"
                            placeholder="关键词" />
                </a-form-item>
                <a-form-item 
                    label="答案"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }">
                    <a-textarea
                        v-model="addForm.answer"
                        placeholder="答案"
                        :maxLength="250"
                        :auto-size="{ minRows: 5 }"
                     />
                </a-form-item>
                <a-form-item 
                    label="状态" 
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }">
                        <a-switch v-model="addForm.enable" />
                    <!-- <a-select v-model="addForm.status"
                                style="width: 120px">
                        <a-select-option value="0">禁用</a-select-option>
                        <a-select-option value="1">启用</a-select-option>
                    </a-select> -->
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="isShowUpdate" title="修改自动回复" @ok="handleUpdate">
            <h4 style="color:#FECD50;text-align:center;">建议您多个关键词之间用空格隔开，方便后期使用</h4>
            <a-form :form="updateObj">
                <a-form-item 
                    label="关键词"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }">
                    <a-input v-model="updateObj.word"
                            placeholder="关键词" />
                </a-form-item>
                <a-form-item 
                    label="答案"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }">
                    <a-textarea
                        v-model="updateObj.answer"
                        placeholder="答案"
                        :maxLength="250"
                        :auto-size="{ minRows: 5 }"
                     />
                </a-form-item>
                <a-form-item 
                    label="状态" 
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 16 }">
                        <a-switch v-model="updateObj.enable" />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
const automaticColumns = [
    {
        title: '关键词',
        dataIndex: 'word',
        align:'center'
    },
    {
        title:'回答',
        dataIndex:'answer',
        align:'center'
    },
    {
        title:'当前状态',
        dataIndex:'enable',
        align:'center',
        scopedSlots: { customRender: 'status' },
    },
    {
        title:'创建时间',
        dataIndex:'create_time',
        align:'center',
        scopedSlots: { customRender: 'time' },
    },
    {
        title: '操作',
        align:'center',
        width:80,
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
    },
]
import {
    mapState
  } from 'vuex'
export default {
    name:'antomatic',
    data(){
        return{
            searchForm:{
                word:'',
                enable:""
            },
            addForm:{
                word:'',
                answer:'',
                enable:true
            },
            loading:false,
            updateObj:{},
            automaticColumns,
            automaticData:[],
            isShowAdd:false,
            isShowUpdate:false
        }
    },
    computed: {
        ...mapState(['mqttInit'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleSearch()
                }
            },
            immediate:true
        }
    },
    methods:{
        handleConfirm(){
            let payload = {
                msgId: "insertReply",
                data:{
                    type:'text',
                    ...this.addForm
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/reply/insert",
                payload,
                },
                (topic, data) => {
                    if(data.success){
                        this.$message.success('新增成功')
                        this.isShowAdd = false
                        this.handleSearch()
                    }
                },
                this
            );
        },
        handleSearch(){
            this.loading = true
            let obj = {}
            if(this.searchForm.word){
                obj.word = this.searchForm.word
            }
            if(this.searchForm.enable==='true'){
                obj.enable = true
            }else if(this.searchForm.enable==='false'){
                obj.enable = false
            }
            
            // console.log(obj)
             let payload = {
                msgId: "searchReply",
                data:{
                    ...obj
                }
            }
            // if(this.automaticData.length>1){
            //     payload.cursor = this.automaticData[this.automaticData.length-1].sort
            // }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/reply/search",
                payload,
                },
                (topic, data) => {
                    this.loading = false
                    if(data.success){
                        
                        this.automaticData = []
                        let val = data.res.map(item=> {
                            return {...item._source,sort:item.sort}
                        })
                        this.automaticData = val
                    }else{
                        this.$message.error('查询失败')
                    }
                },
                this
            );

        },
        resetSearchForm(){
            this.searchForm ={
                word:'',
                status:""
            }
        },
        handleAddAutomatic(){
            this.addForm = {
                word:'',
                answer:'',
                enable:true
            }
            this.isShowAdd = true
        },
        updateAutomatic(item){
            this.updateObj = item
            this.updateObj.status = Boolean(this.updateObj.status)
            this.isShowUpdate = true
        },
        handleUpdate(){
            let {id,word,enable,answer} = this.updateObj
            
            let payload = {
                msgId: "updateReply",
                data:{
                    id,word,enable,answer
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/reply/update",
                payload,
                },
                (topic, data) => {
                    if(data.success){
                        this.$message.success('更改成功')
                        this.isShowUpdate = false
                        setTimeout(()=>{
                            this.handleSearch()
                        },200)
                        
                    }
                },
                this
            );
        }
    }
}
</script>
<style lang="scss" scoped>
    .show-item{
        margin-bottom: 20px;
        padding-left:40px ;
    }
</style>